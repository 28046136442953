// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/components/copy_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/home_send_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/home_receive_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/svg/home_scan_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/svg/home_search_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/svg/search.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./home.scss"></require>
  <require from="../../components/header-titles/header-title-home/header-title-home"></require>
  <require from="../../components/ValueConverters/currency-denomination"></require>
  <require from="../../components/number-format-eur"></require>
  <require from="../../components/number-format-bsv"></require>
  <require from="../../components/date-format-full-day"></require>
  <require from="../../components/mybankabrand/mybankabrand"></require>
  <require from="../../components/ValueConverters/iban-converter"></require>
  <require from="../../components/loader-ring/loader-ring"></require>
  
  <section id="home">
    <header-title-home  title.bind="title" subtitle.bind="subtitle" 
    make-payment.call="initiatePayment(state.wallets[state.selectedAccountIndex])"
    receive-payment.call="generateQrCode(state.wallets[state.selectedAccountIndex])"></header-title-home>
    
    <div class="phone_header flex-row hidden_on_desktop">
      <img 
        class="profile_pic" if.bind="me.urlPic" id="profile" src="\${me.urlPic}"
        click.delegate="goToProfile()" />
      <img class="profile_pic"
         if.bind="!me.urlPic" id="profile"
        src="${___HTML_LOADER_IMPORT_0___}" click.delegate="goToProfile()" />
      <div class="flex-grow"></div>
      <h2 class="page_title">Home</h2>
      <mybankabrand></mybankabrand>
      <div class="flex-grow"></div>
    </div>
    <div class="cards_container flex-column">
      <div class="card_part flex-row">
        <div 
          class="card_container active flex-column" if.bind="state.wallets[state.selectedAccountIndex]">
          <div class="info_pic_container flex-row">
            <div class="info_container flex-column">
              <p class="name" click.delegate="goToChooseWallet()">\${state.wallets[state.selectedAccountIndex].name}</p>
              <div class="identification_copy_container flex-row" click.delegate="copy()">
                <p class="identification" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">\${state.wallets[state.selectedAccountIndex].identification}</p>
                <p class="identification" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain && !state.wallets[state.selectedAccountIndex].isError">\${state.wallets[state.selectedAccountIndex].identification | ibanConverter}</p>
                <p class="identification" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain && state.wallets[state.selectedAccountIndex].isError">\${state.wallets[state.selectedAccountIndex].identification}</p>
                <img id="first-svg" class="copy" src="${___HTML_LOADER_IMPORT_1___}" ref="copyPaymailButton">
                <img id="second-svg" class="copy_check" src="${___HTML_LOADER_IMPORT_2___}" ref="copyPaymailButtonCheck">
              </div>
            </div>
          </div>
          <p class="flex-grow spaacing" click.delegate="goToChooseWallet()"></p>
          <div class="balance_currency_container flex-row" click.delegate="goToChooseWallet()">
            <p class="balance" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain  && state.wallets[state.selectedAccountIndex].currency !== 'EUR'">
              \${state.wallets[state.selectedAccountIndex].currency | currencyDenomination }
              \${balance | numberFormatEur:localeForCurrency}
            </p>
            <p class="balance" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain && state.wallets[state.selectedAccountIndex].currency === 'EUR'">
              \${balance | numberFormatEur:localeForCurrency}\${state.wallets[state.selectedAccountIndex].currency | currencyDenomination }
            </p>

            <p class="balance" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">
              \${balance | numberFormatBsv:localeForCurrency}
            </p>
          </div>
        </div>
        <div click.delegate="router.navigateToRoute('choose_type_account')">
          <div class="empty">
            <div class="empty_inner">
              <p>
                +
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="features_buttons hidden_on_desktop">
        <div class="send_generate flex-row">
          <div click.delegate="initiatePayment(state.wallets[state.selectedAccountIndex])">
            <img src="${___HTML_LOADER_IMPORT_3___}" />
            <span i18n="home.send">Send</span>
          </div>
          <div click.delegate="generateQrCode(state.wallets[state.selectedAccountIndex])">
            <img src="${___HTML_LOADER_IMPORT_4___}" />
            <span i18n="home.receive">Generate</span>
          </div>
        </div>
        <div class="scan_contacts flex-row">
          <div click.delegate="router.navigateToRoute('scan_qr_code')">
            <img src="${___HTML_LOADER_IMPORT_5___}" />
            <span i18n="home.scan">Scan</span>
          </div>
          <div click.delegate="router.navigateToRoute('transactions')">
            <img src="${___HTML_LOADER_IMPORT_6___}" />
            <span i18n="home.search">Search</span>
          </div>
        </div>
      </div>
    </div>
    <div id="transactions">
      <div class="header">
        <h2 t="home.transactions.transactions1" click.delegate="goToTransactions()" style="cursor: pointer;"></h2>
        <div class="flex-grow"></div>
        <loader-ring if.bind="loader"></loader-ring>
        <div class="hidden_on_mobile">
          <img src="${___HTML_LOADER_IMPORT_7___}">
          <input i18n="[placeholder]home.search" value.bind="search_term" onkeydown="return (event.keyCode!=13);"/>
        </div>
      </div>
      <div class="titles flex-row">
        <p class="name" t="home.titles.name">Name</p>
        <p class="amount" t="home.titles.amount">Amount</p>
        <p class="action"></p>
      </div>
      <div class="transaction-box">
        <div 
        class="transaction"
        class.bind="state.wallets[state.selectedAccountIndex].transactions[0] ? 'first_transaction' : ''"
        repeat.for="transaction of search_results_transactions"
        >
        <p class="picture">
          <img if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain"  src.bind="transaction.urlPic" onerror="this.onerror=null; this.src='/img/bank_account_white.png';" />
          <img if.bind="state.wallets[state.selectedAccountIndex].isBlockchain"   src.bind="transaction.urlPic" onerror="this.onerror=null; this.src='/img/currency/bitcoin/bitcoin_account_white.png';" />
        </p>
        <div class="transaction-left flex-column">
          <span class="name">\${transaction.senderName}</span>
          <p class="account" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain">
            \${transaction.senderIdentification | ibanConverter}
          </p>
          <p class="account" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">
            \${transaction.senderIdentification}
          </p>
        </div>
        <div class="flex-grow comment transaction-middle">
        </div>

        <div id="right-bank" class="transaction-right flex-column" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain">
          <div class="amount">
            <p class="balance">
              \${transaction.amount | numberFormatEur:localeForCurrency}
              \${transaction.currency | currencyDenomination }
            </p>
          </div>
          <p class="date" >
            \${transaction.createdAt | dateFormatFullDay}
          </p>
        </div>

        <div id="right-bitcoin" class="transaction-right flex-column" click.delegate="redirectToWhatsOnChain(transaction.transactionId)" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">
          <div class="amount">
            <p class="balance">
              \${transaction.amount | numberFormatBsv:localeForCurrency}
            </p>
          </div>
          <p class="date">
            \${transaction.createdAt | dateFormatFullDay}
          </p>
        </div>
      </div>
      <div class="no_tranaction flex-row" if.bind="search_results_transactions.length < 1">
        <p t="bank.bank_no_transaction"></p>
      </div>  
    </div>
  </section>
</template>`;
// Exports
export default code;